import { defineStore } from 'pinia';

import { fetchWrapper } from '@/helpers/auth';
import common from "@/composables/common";
const url = `users/`;

export const useUserStore = defineStore({
    id: 'user',
    token: null,
    state: () => ({
        user: JSON.parse(localStorage.getItem("user")) || null,
        personal: false,
        subscribed: false,
    }),
    actions: {

        getToken() {
            const user = JSON.parse(localStorage.getItem("user")) || null;
            if (user.token) {
                return user.token;
            } else {

            }
        },

        PersonalViewed(val) {
            this.personal = val
        },

        setUser(name,hash, token) {
            this.user = {name: name, hash: hash, token: token}
            common.saveToLocalStorage("user", this.user)
        },
        setSubscribed(value) {
            this.subscribed = value
        },
    }
});