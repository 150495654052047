<template>
  <div class="anketa-completion">

    <div class="completion">

      <span class="logo">
        <img src="@/assets/img/anketa/ostin-capsula-logo.svg">
      </span>

      <div class="title">
        Спасибо тебе!
        <span class="description">
          Наши стилисты узнали все что<br>необходимо, и скоро мы вас удивим.
        </span>
      </div>

      <timer-button
          :text="button.text"
          :link="button.link">
      </timer-button>

      <div class="completion-footer">
        <div class="text">Включи push-уведомления, чтобы не пропустить</div>
        <div class="switch">
          <div>
            <Switch
            v-model:checked="notifications"
            @toggle="notifications"
          />
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>

import {useHeaderStore} from '@/stores/header'
import anketaMixin from "@/mixins/anketaMixin";
import {useAnketaStore} from "@/stores/anketa";
import {ref} from "vue";
import bottomData from "@/data/anketa/bottomData";
import Switch from "@/components/UI/Switch";
import TimerButton from "@/components/UI/TimerButton";
import headerMixin from "@/mixins/headerMixin";


export default {
  name: 'TopQuestionView',
  components: {TimerButton, Switch},
  mixins: [headerMixin],
  props: ['header'],
  setup() {

    const { setValue,getStepData,steps } = useAnketaStore();
    const notifications = ref(getStepData("Notifications"))
    const {setHeaderData} = useHeaderStore();
    return {
      setHeaderData, setValue,getStepData,steps,notifications
    };
  },
  watch:{
    notifications(newVal){
      this.setValue('Notifications',newVal)
    }
  },
  data() {
    return {
      title: "<span>Выбери, какой у тебя размер низа:</span>",
      button: {
        text: 'ОБРАЗЫ ДЛЯ ТЕБЯ СКОРО',
        link: 'anketa.completion',
      },
      data: bottomData,
      step_number: "step9",
    }
  }
}
</script>

<style lang="less">
@import '@/assets/less/completion.less';
</style>
