<template>
  <div class="header">
    <a v-if="!header.backLink" @click="$router.go(-1)">
      <div class="back" v-if="header.back"></div>
    </a>

    <a v-else @click="$router.push({name: header.backLink})">
      <div class="back" ></div>
    </a>

    <span class="title">{{ header.title }}
    </span>
    <div class="link" v-if="!header.removeClose"
         @click="$router.push({name:'home'});appEvent('onAppClose')">

    </div>
  </div>
</template>

<script>
import { useHeaderStore } from '@/stores/header'
import {useRoute} from "vue-router";
import appMixin from "@/mixins/appMixin";

export default {
  name: 'Header',
  mixins: [appMixin],
  setup() {
    const store = useHeaderStore()
    const { header } = store;
    const route = useRoute()
    return {header,route}
  }
}
</script>


<style scoped>

</style>